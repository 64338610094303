import request from '@/utils/request'

/**
 * 获取分类树
 * @param {Object} params - 请求参数
 */
export function treeCategory(params) {
  return request({
    url: '/api/category/tree',
    method: 'get',
    params: params
  })
}
