import request from "@/utils/request.js"

/**
 * 获取注册验证码
 * @param data
 */
export function sendRegisterCode(data) {
    return request.request({
        url: '/api/user/register/code',
        method: 'post',
        data:data
    })
}

/**
 * 获取重置密码验证码
 * @param data
 */
export function sendRepassCode(data) {
    return request.request({
        url: '/api/user/repass/code',
        method: 'post',
        data:data
    })
}


// /**
//  * 兑换会员
//  */
// export function redemptionMember() {
//     return request.request({
//         url: '/api/user/redemptionMember',
//         method: 'post',
//     })
// }

/**
 * 获取用户文章收藏列表
 * @param params
 */
export function listUserArticleFavorite(params) {
    return request.request({
        url: '/api/user/article/favorite/list',
        method: 'get',
        params:params
    })
}

/**
 * 保存用户文章收藏
 * @param data
 */
export function saveUserArticleFavorite(data) {
    return request.request({
        url: '/api/user/article/favorite/save',
        method: 'post',
        data
    })
}

/**
 * 获取用户文章权限列表
 * @param params
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function listUserArticlePowerByUser(params) {
    return request.request({
        url: '/api/user/article/power/list',
        method: 'get',
        params:params
    })
}

/**
 * 判断用户是否有文章权限
 * @param params
 */
export function isUserArticlePower(params) {
    return request.request({
        url: '/api/user/article/power/isHave',
        method: 'get',
        params:params
    })
}

/**
 * 保存文章权限
 * @param data
 */
export function saveArticlePower(data) {
    return request.request({
        url: '/api/user/article/power/save',
        method: 'post',
        data:data
    })
}

/**
 * 获取积分明细
 * @param params
 */
export function lisIntegralDetailsByUser(params) {
    return request.request({
        url: '/api/user/integral/details/list',
        method: 'GET',
        params
    })
}

/**
 * 判断用户是否签到
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function isSignIn() {
    return request.request({
        url: '/api/user/isSignIn',
        method: 'GET',
    })
}

/**
 * 签到
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function signIn() {
    return request.request({
        url: '/api/user/signIn',
        method: 'GET',
    })
}

/**
 * 获取我的积分
 */
export function getMyIntegral() {
    return request.request({
        url: '/api/user/myIntegral',
        method: 'GET',
    })
}


export function uvCount() {
    return request.request({
        url: '/api/user/count/uv',
        method: 'GET',
    })
}

/**
 * 注册
 * @param data
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function register(data) {
    return request.request({
        url: '/api/user/register',
        method: 'post',
        data:data
    })
}

/**
 * 注销
 */
export function logout() {
    return request.request({
        url: '/api/user/logout',
        method: 'post',
    })
}

/**
 * 登录
 * @param data
 */
export function login(data) {
    return request.request({
        url: '/api/user/login',
        method: 'post',
        data:data
    })
}

/**
 * 验证重置密码
 * @param data
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function verifyRepassCode(data) {
    return request.request({
        url: '/api/user/verify/repass/code',
        method: 'post',
        data:data
    })
}

/**
 * 重置密码
 * @param data
 */
export function resetPassword(data) {
    return request.request({
        url: '/api/user/password/reset',
        method: 'put',
        data:data
    })
}


/**
 * 更新用户信息
 * @param data
 */
export function updateUser(data) {
    return request.request({
        url: '/api/user/update',
        method: 'put',
        data:data
    })
}

/**
 * 更新用户头像
 * @param data
 */
export function updateUserHead(data) {
    return request.request({
        url: '/api/user/update/head',
        method: 'put',
        data:data
    })
}

/**
 * 更新密码
 * @param data
 */
export function updatePassword(data) {
    return request.request({
        url: '/api/user/password/update',
        method: 'put',
        data:data
    })
}

/**
 * 获取用户信息
 * @param params
 */
export function getMeInfo(params) {
    return request.request({
        url: '/api/user/me',
        method: 'get',
        params:params
    })
}

export function saveSearchHistory(data) {
    return request.request({
        url: '/api/history/search',
        method: 'post',
        data:data
    })
}

