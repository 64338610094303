import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        component: () => import('@/views/user/login.vue'),
        name: 'user-login',
    },
    {
        path: '/loginout',
        component: () => import('@/views/user/loginout.vue'),
        name: 'user-loginout',
    },
    {
        path: "/register",
        component: () => import('@/views/user/register.vue'),
        name: 'user-register',
        meta: {
            title: '用户注册 - QJGAME'
        }
    },
    {
        path: '/',
        name: 'Layout',
        redirect: "/",
        component: () => import('@/layout/index.vue'),
        children: [
            {
                path: '/404',
                component: () => import('@/views/error/404.vue'),
                name: 'error-404',
            },
            {
                path: "/user/repass",
                component: () => import('@/views/user/repass.vue'),
                name: 'user-repass',
                meta: {
                    title: '找回密码 - QJGAME'
                }
            },
            {
                path: 'user',
                component: () => import('@/views/user/index.vue'),
                name: 'user-index',
                redirect: "/user/general",
                children: [
                    {
                        path: 'integral',
                        component: () => import('@/views/user/component/integral.vue'),
                        name: 'user-integral',
                        meta: {
                            title: '我的积分 - QJGAME'
                        }
                    },
                    {
                        path: 'general',
                        component: () => import('@/views/user/component/general.vue'),
                        name: 'user-general',
                        meta: {
                            title: '基本资料 - QJGAME'
                        }
                    },
                    {
                        path: 'password',
                        component: () => import('@/views/user/component/password.vue'),
                        name: 'user-password',
                        meta: {
                            title: '修改密码 - QJGAME'
                        }
                    },
                    {
                        path: 'favorite',
                        component: () => import('@/views/user/component/favorite.vue'),
                        name: 'user-favorite',
                        meta: {
                            title: '我的收藏 - QJGAME'
                        }
                    },
                    {
                        path: 'obtain/record',
                        component: () => import('@/views/user/component/record/obtain'),
                        name: 'user-obtain-record',
                        meta: {
                            title: '修改密码 - QJGAME'
                        }
                    },
                ]
            },
            {
                path: '/',
                component: () => import('@/views/index/index.vue'),
                name: 'index',
                props: true,
            },
            {
                path: '/demand',
                component: () => import('@/views/demand/index.vue'),
                name: 'article-demand',
                meta: {
                    title: '反馈建议 - QJGAME'
                }
            },
            {
                path: '/detail/:id',
                component: () => import('@/views/article/detail.vue'),
                name: 'article-detail',
                props: true,
            },
            {
                path: '/download',
                component: () => import('@/views/article/download.vue'),
                name: 'article-download',
                props: true,
            },
            {
                path: ':first',
                component: () => import('@/views/article/list.vue'),
                name: 'article-list-1',
                props: true,
            },
            {
                path: ':first/:second',
                component: () => import('@/views/article/list.vue'),
                name: 'article-list-2',
                props: true,
            },
        ]
    },
    {
        path: '*',
        redirect: "404"
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

router.beforeEach((to, from, next) => {
    document.title = "QJGAME - 个人分享网站"
    // 让页面回到顶部
    document.documentElement.scrollTop = 0;

    if(to.path === '/login'){
        let referrer = '';
        if (from.path === '/loginout' || from.path === '/login' || from.path === '/register'){
            referrer = "/"
        }else {
            referrer = from.path;
        }
        //储存来源路由
        sessionStorage.setItem('referrer',referrer)
    }

    next();
});

export default router
